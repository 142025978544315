import React, { useState} from "react";
import letcContext from "../Context/letcContext";
import { Link } from "react-router-dom";
import examsList from "../Assests/exams-sheet.jpg";
import SEO from './SEO';

const Exam = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <>
    <SEO
        title="Notifications"
        description="Stay updated with the latest news, announcements, and schedules from LETC Education in Bangalore."
        keywords="LETC Education notifications, news updates, exam schedules, announcements, Bangalore coaching center"
      />
    <div className="md:pl-8 py-14 mt-20 md:mt-0 bg-blue-100 space-y-20">
      <div className="exam-notifications p-5   rounded-lg">
      <div className="mb-16">
        <h3 className="font-extrabold text-slate-800 text-3xl mb-5 tracking-wide text-center">
          Exam Notifications - Get all upcoming exam updates
        </h3>
      </div>
      <div className="w-full flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            { title: "JEE MAIN EXAM-2025", link: "https://jeemain.nta.nic.in" },
            { title: "NEET UG EXAM-2025", link: "https://neet.nta.nic.in" },
            { title: "KEA Common Online Application-2025", link: "https://cetonline.karnataka.gov.in/UGONLINEAPPLICATION_2024/FORMS/Loginpage.aspx" },
            { title: "COMEDK UGET-2025", link: "https://www.comedk.org" },
            { title: "JEE ADVANCED-2025", link: "https://www.jeeadv.ac.in" },
            { title: "KCET COUNSELLING-2025", link: "https://cetonline.karnataka.gov.in/kea/" },
          ].map((exam, index) => (
            <div
              key={index}
              className={`exam-notifications p-4 bg-blue-50 rounded-lg shadow hover:bg-blue-100 transition duration-300 ease-in-out transform ${
                activeIndex === index ? "scale-105" : "hover:scale-105"
              }`}
              onClick={() => handleClick(index)}
            >
              <p className="font-semibold text-lg text-[#2E3192] tracking-wide mb-2 break-words">
                {exam.title}
              </p>
              <Link
                to={exam.link}
                target="_blank"
                className="font-medium text-xs text-red-500 tracking-wide hover:underline"
              >
                Click here
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
      
    </div>
    </>
  );
};

export default Exam;
