// src/components/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title ? `${title} - LETC Education` : 'LETC Education - Bangalore Coaching for Class 8-12, IIT JEE, NEET & Commerce'}</title>
      <meta name="description" content={description || 'LETC Education provides top coaching in Bangalore for high school, IIT JEE, NEET, and commerce students.'} />
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
}

export default SEO;
