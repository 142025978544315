import { useEffect } from "react";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import courseTop from "../Assests/course-top.jpg";
import cet from '../Assests/kcet.png'
import SEO from './SEO';
const Cet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const EligibilitySection = ({ title, criteria }) => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">What is KCET Exam in India?</h2>
      <p className="text-lg text-gray-600 text-justify max-w-8xl mx-auto mb-12">KCET stands for Karnataka Common Entrance Test, and Karnataka Examination Authority hosts it. The exam was designed for the students in Karnataka to get admission to the professional courses. At the moment, you can apply for KCET if you wish to pursue B.Tech, B.Pharm, D.Pharm, Farm Science Course and Veterinary Course. You should note that KCET doesn’t help you in getting admission to a medical course. You still have to appear in NEET for the medical courses, but for all technology-related courses, KCET should help you. Apart from the courses listed above, you can also go for a bachelor in architecture with the help of the KCET Exam.<br/>The KCET is organized once a year, and the exam is scheduled offline. You have to give the exam with the help of paper and a pen. In addition to this, there are two papers in the series that you need to appear in. At the moment, 211 colleges accept the KCET score, and you can get admission to those colleges depending on your cut-off. You now have a fair idea of what KCET is all about. Let us now go through the eligibility criteria, syllabus and other similar things so that you can appear in the exam.</p>
    </div>
  );

  const QualifyingCodesSection = () => (
  
    
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">Eligibility Criteria for KCET Exam

</h2>
      <p className="text-lg text-gray-600 text-justify max-w-8xl mx-auto mb-1">Let us now talk about the eligibility criteria around the KCET exam. Check out the pointers below:</p>

      <p className="text-lg text-gray-600 text-justify max-w-8xl mx-auto mb-12">
        <br/>
      <span className="text-2xl font-bold text-indigo-600 mb-2">B.Arch:</span>
  <br/>
  1. The candidate should have cleared class 12th with English as one of the languages.<br/>
2. The candidate should have also scored 50% in Physics, Maths and Chemistry. The overall score should also be 50%, and there is a relaxation of 5% for reserved category students.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">B.Tech/B.E:</span><br/>
1. For B.Tech, the candidate should have cleared the 12th class with English as one of the languages.<br/>
2. The minimum marks required in Physics, Chemistry and Maths is 45%. You should have a similar score in the 5th Here as well, there is a 5% relaxation for reserved category students.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">Farm Science Courses:</span><br/>
1. The eligibility criteria are very relaxed for Farm Science Courses. The candidate should have just cleared class 12th with PCM as the main subjects and Biology as optional.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">Bachelor of Veterinary Science:</span><br/>
1. For any Veterinary Science course, the candidate should have cleared 12th class with PCB with English as a language.<br/>
2. The candidate should have a minimum score of 50%; however, there is a 10% relaxation for the reserved category student.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">B.Pharma/D.Pharma:</span><br/>
1. For B.Pharma, the candidate should have cleared 12th with PCB or PCM.<br/>
2. The minimum marks required for the student is 45%. However, there is a 5% relaxation for the reserved category. The same is the eligibility criteria for the Diploma in Pharma courses.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">Yoga & Naturopathy:</span><br/>
1. If the candidate wishes to pursue a Yoga or Naturopathy course, the candidate should have cleared 12th with PCB, and he should have scored 50% in the subjects. Moreover, there is a 40% minimum score requirement for reserved category students.




<br/>
<br/>
Apart from the eligibility criteria listed above, please note that there is a reservation for the reserved category, rural candidates, Kannada medium candidates, J&K Migrants, and Article 371(J). Lastly, the candidate should be at least 17 years old to appear in the KCET exam.
      </p>
    </div>
  );


  const QualifyingCodesSection1 = () => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">KCET Exam Syllabus & Pattern</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">
      In this section, we have listed the syllabus and pattern of the KCET Exam. Check out the details below:
<br/>
<br/>
1. The exam has three to four sections depending on your category.<br/>
2. The first section is Physics that has 60 questions. The second section has 60 questions from chemistry, and the third section has 60 questions from mathematics or biology. The third section depends on the choice of your course and your education qualification.<br/>
3. You get 1 minute to answer every question, and this way, you get 180 minutes to complete the three sections.<br/>
4. If you have applied for the Kannada exam, then you will have to answer additional 50 questions.<br/>
5. In terms of the syllabus, you would need to prepare everything that you studied in class 11th and class 12th. All the chapters are equally important, and you need to prepare all for getting a good score in KCET.<br/>
      </p>

      

    </div>
  );


  const QualifyingCodesSection2 = () => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">Attempt for KCET Exam</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">There is no such restriction on the number of attempts available for the KCET exam. You can appear in multiple attempts. As mentioned earlier, KCET is hosted annually so that you can plan your attempt accordingly.</p>
    <br/>
    <br/>

    <h2 className="text-3xl font-bold text-indigo-600 mb-6">Fee for KCET Exam?</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">The fee for the general category students who reside in Karnataka is Rs 650. For the general category students outside Karnataka, the fee is RS 750. There is a concession available for SC/ST and CAT-1 students as they need to pay only Rs 500 for the form. Lastly, the candidates outside India are required to pay Rs 5000 for the form.</p>

      

    </div>
  );


  const QualifyingCodesSection3 = () => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">The Registration Process of KCET Exam</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">

      We have documented the process to apply for the KCET exam in the below section. Go through the steps and apply for KCET now.
<br/>
<br/>
1. You can start the application procedure by visiting the official website of KEA. The link to the website is https://cetonline.karnataka.gov.in/kea/.<br/>
2. On the website, you need to click on the CET UG Online Application. A page with the instructions will be displayed.<br/>
3. Go through the instructions and proceed to the next step in order to fill the form. You can now create your account and enter all the details. Ensure that the academic and personal details are entered correctly.<br/>
4. You will now be asked to upload the passport size photo. Upload the photo along with the left hand thumb impression. Apart from this, you will have to upload your and your parent’s signature. All these should be in JPEG or JPG Format.<br/>
5. Now, you need to make the payment of the fee. You can pay the fee online through net banking, debit card, credit card or UPI. If you wish to pay the fee offline, you can print the challan and visit the bank to make the payment. It is also possible to pay the fee through DD.<br/>
6. In the next step, take the print of the form and get it attested by the school principal where you completed 12th Once that is done, you need to keep the document safely, and you will need it during the admissions.<br/>
7. The admit card is made available to you later with the details of the centre. You can then appear in the exam, and depending on the cut-off, you can appear in the colleges’ counselling.<br/>
      </p>
    <br/>
    <br/>

   

      

    </div>
  );


  return (
    <>
     <SEO
        title="CET Exam Coaching"
        description="Prepare for Karnataka CET with LETC Education's expert coaching in Bangalore. Achieve success in engineering, medical, and other fields."
        keywords="CET exam coaching, Karnataka CET preparation, LETC Education Bangalore, CET coaching Bangalore"
      />
    <div className="container mx-auto py-14 mt-20 md:mt-0 px-6">
      <div className="course-img mb-12">
        <img
          src={cet}
          alt="Course"
          className="w-full h-[300px] rounded-3xl object-cover shadow-lg"
        />
      </div>
      <div className="about-us mb-16">
        <h1 className="font-bold text-indigo-700 text-4xl mb-8 tracking-wide text-center">
        What is KCET Exam in India? Eligibility, Syllabus, Age Limit, Fees, Registration
        </h1>
        <p className="text-lg text-gray-600 text-center max-w-6xl mx-auto mb-12">
        Many national level tests help the students in getting admission to professional courses like B.Tech. Now, apart from the national level tests, some state-level tests are organized by the state itself for the domiciles. KEAM is an example of one such test that is hosted in Kerala. Similarly, there is also a test organized in Karnataka, and the name of this exam is KCET. This is an exclusive entrance test for students with domicile in Karnataka. With the help of KCET, you will be able to seek admission to the institutes in Karnataka. If you plan to appear in KCET or if you would like to learn more about KCET, then you can scroll down and check out the various sections on the page.
        </p>
        <EligibilitySection
          
        />
        <QualifyingCodesSection />
        <QualifyingCodesSection1 />
        <QualifyingCodesSection2 />
        <QualifyingCodesSection3 />
        <div className="text-center mt-12">
          <a
            href="https://cetonline.karnataka.gov.in/kea/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
          >
            Visit Official KCET Website
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default Cet;
