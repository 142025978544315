import React from "react";
import aboutUs from "../Assests/about-us.jpg";

const AboutUs = () => {
  return (
    <div className="px-5 md:px-10 lg:px-20 py-10">
      <div className="about-items md:flex border shadow-lg p-5 md:space-x-8 bg-slate-200 rounded-lg">
        <div className="about-image md:w-2/ flex items-center mb-5 md:mb-0">
          <img
            src={aboutUs}
            loading="lazy"
            alt="About Us"
            className="object-fit h-full w-full rounded-lg shadow-md"
          />
        </div>
        <div className="about-text md:w-2/3 py-3">
          <h3 className="text-[#2E3192] text-3xl font-bold text-center mb-5">
            About Us
          </h3>
          <p className="text-lg mb-3 text-justify leading-relaxed">
            <b>L.E.T.C</b> was established with a great ambition of providing
            quality services in the field of education in the academic year
            2009. A team of experienced and dedicated faculties took the
            responsibility of training aspiring students for national and state
            level Engineering and Medical Entrance Examinations under the
            visionary leadership of the management of <b>L.E.T.C</b>.
          </p>
          <p className="text-lg mb-3 text-justify leading-relaxed">
            It is very proud to mention here that today <b>L.E.T.C</b> has
            emerged as one of the most successful coaching centers in North
            Bangalore with a proven track record of results in the previous
            years.
          </p>
          <p className="text-lg mb-3 text-justify leading-relaxed">
            <b>L.E.T.C</b> has become the most sought-after and successful
            coaching center in North Bangalore. From the year 2010, we have
            been coaching students for IX and X exams. This academic year, IX +
            IIT/NEET Foundation and X + IIT/NEET Foundation have been started
            with greater conviction. Currently, hundreds of students are
            studying in our established three branches, and students of XI, XII,
            I PUC, and II PUC are being trained for various competitive exams
            like JEE (Main), BITSAT, K-CET, COMEDK, NEET, AIIMS, and JIPMER.
          </p>
          <p className="text-lg mb-3 text-justify leading-relaxed">
            Apart from the science stream, one more popular stream among 10th
            passed-out children is Commerce. The Commerce stream offers a range
            of career options for their future. We at L.E.T.C have therefore
            included the Commerce stream among our existing offerings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
