import React, { useState, useEffect, useRef } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import image01 from "../Assests/slidenew1.jpg";
import image02 from "../Assests/slidenew2.jpg";
import image03 from "../Assests/slidenew3.jpg";



const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeout = useRef(null);
  const length = 3; // Number of static images

  // Static image URLs
  const staticSlides = [
    image01,
    image02,
    image03,
  ];

  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((currentIndex) =>
        currentIndex === length - 1 ? 0 : currentIndex + 1
      );
    };

    timeout.current = setTimeout(nextSlide, 3000);

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [currentIndex, length]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="md:max-w-[1400px] md:h-[600px] w-full h-[260px] m-auto relative group mb-3 mt-24 md:mt-0">
      {/* Static image with the current index */}
      <div
        style={{
          backgroundImage: `url(${staticSlides[currentIndex]})`,
          
        }}
        className="w-full h-full bg-center bg-cover duration-500"
      ></div>
      {/* Left Arrow */}
      <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      {/* Slide indicators */}
      <div className="flex top-4 justify-center py-2">
        {staticSlides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`text-2xl cursor-pointer ${
              currentIndex === slideIndex ? "text-blue-500" : "text-gray-300"
            }`}
          >
            ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
