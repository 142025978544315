import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../Assests/youtube1.png';
import img2 from '../Assests/youtube2.png';
import img3 from '../Assests/youtube3.png';
import img4 from '../Assests/youtube4.png';

const VideoSlider = () => {
    const videos = [
        { id: '3DUTbfJOndg?si=IODSBvp53ybVKppN', thumbnail: img1 },
        { id: 'zqzF606-7OE?si=wm1lgYUDF0o-ISFw', thumbnail: img2 },
        { id: 'pLXH0tY2_PQ?si=Q2LSRhZLHKe5JGk1', thumbnail: img3 },
        { id: 'ipUKMav-32o?si=tsYbTCZe3pz4tJTr', thumbnail: img4 },
        
    ];

    const settings = {
        dots: true, // Enable dots for navigation
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        autoplay:true,
        autoplayspeed:3000,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 768, // For tablets
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480, // For mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="video-slider" style={{ textAlign: 'center', padding: '30px', borderRadius: '8px' }}>
            <Slider {...settings}>
                {videos.map((video, index) => (
                    <div 
                        key={index} 
                        style={{ 
                            cursor: 'pointer', 
                            padding: '10px',  // This adds spacing between images
                           
                        }} 
                        onClick={() => window.open(`https://www.youtube.com/watch?v=${video.id}`, '_blank')}
                    >
                        <div 
                            style={{ 
                                overflow: 'hidden', 
                                padding:'10px',  // Ensure border-radius works
                                // Optional: Adds some shadow for visual separation
                            }}
                        >
                            <img 
                                src={video.thumbnail} 
                                loading='lazy'
                                alt={`Thumbnail for ${video.id}`} 
                                style={{ 
                                    width: '100%', 
                                    height: '350px',
                                    borderRadius:'10px',
                                    display: 'block', // Ensures no extra space below the image
                                }} 
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default VideoSlider;
