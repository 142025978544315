import { useEffect } from "react";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import courseTop from "../Assests/course-top.jpg";
import neet from '../Assests/neetbanner.jpeg';
import SEO from './SEO';
const Neet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const EligibilitySection = ({ title, criteria }) => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">A Brief on NEET Exams</h2>
      <p className="text-lg text-gray-600 text-justify max-w-8xl mx-auto mb-12">NEET exams are conducted and governed by the National Testing Agency who mandates the same under the regulation of the Ministry of Health and Family Welfare. The exam replaced the previous medical entrance exam All India Pre Medical Test (AIPMT) and is the current sole entrance exam for the medical field. The exam acts as the entry point for medical, dental, AYUSH, and veterinary careers ahead. The exams are conducted once a year and have an exam duration of 3 hours.</p>
    </div>
  );

  const QualifyingCodesSection = () => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">Eligibility for NEET Exam</h2>
      <p className="text-lg text-gray-600 text-justify max-w-8xl mx-auto mb-1">The NEET exam mandates certain rules and regulations to be followed by the apprising students to become eligible for the exam. The eligibility criteria revolve around four different factors as nationality, subjects taken, minimum marks, and qualification achieved. We will take a look at all these segments in brief here:</p>

      <p className="text-lg text-gray-600 text-justify max-w-8xl mx-auto mb-12">
        <br/>
      <span className="text-2xl font-bold text-indigo-600 mb-2">Nationality:</span>
  <br/>
Only students who fit the category of Indian National, NRI, Overseas Citizen of India, Person of Indian Origin can apply for the exam. However, the exam also allows foreign nationals to apply provided they fulfill other criteria and the rules.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">Minimum Qualification required:</span><br/>
The NEET exam has a mandate that requires students to have cleared their class 12 or any such equivalent course from a recognized board to become eligible for the exam. It also allows students who have completed their courses from open school to apply for the exam. Furthermore, students who have appeared or are about to appear for the Grade 12 exams can also apply for the NEET exams of the same year.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">Mandatory subjects taken:</span><br/>
Since NEET exams are entrance papers for students to embark into the medical field, certain streams of science are compulsory for the students. The subjects are Physics, Chemistry, Biology are compulsory. Students who have taken Mathematics with optional Biology can also apply for the NEET exams.
<br/>
<br/>
<span className="text-2xl font-bold text-indigo-600 mb-2">Markings or Grade required:</span><br/>
As a student applying for the exam you also need to have attained a certain minimum marking to become eligible for it. Here are the minimum marks required for different categories:
General Category: Should have at least 50% marks
PWD Category: Should have at least 45% marks
SC/ST/OBC Category: Should have at least 40% marks
      </p>
    </div>
  );


  const QualifyingCodesSection1 = () => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">NEET Exam Syllabus</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">The syllabus of the NEET exam revolves around the core subjects of science as the field in itself is the gateway to the medical science profession. The syllabus is designed by the NTA based on the course material of the Grade 11 and 12 students. The major subjects required by the students to prepare are Physics, Chemistry, and Biology. The syllabus is designed so that it checks the level of understanding and the skillset of students into these three subjects and what they learned during their +2.

The syllabus also has a few topics that are outside of the course material of the Grade 11 and 12. Generally, the syllabus is released every year with updated reports on any changes or addition. For the time being, there has been no change in the current year syllabus from the past year. Though in the future certain reductions may be done in the syllabus to decrease its length. The syllabus is designed to help students continue their learning from what they learned during the Grade 11 and 12 so that they are in tune with the knowledge required for the medical field.The exams are conducted based on this syllabus and consist of a total of 180 questions. The pattern for the exam is designed as 45 questions from Physics and Chemistry each and a total of 90 remaining questions from Biology. The students will achieve 4 marks for every correct answer while the wrong answer will result in a negative marking of 1 mark. The total maximum attainable marks are 720 for the NEET exams.</p>

      

    </div>
  );


  const QualifyingCodesSection2 = () => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">Age Limit for NEET Exams</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">As is the case with the eligibility criteria aforementioned, there is also an age limit for the candidates to apply for the NEET exams. This is made so that only eligible and desirable candidates join the stream and no stoppage of opportunities are there for candidates. The rules mandate that the minimum age of the students should be 17 years while appearing for the NEET exams. The maximum age was previously set at 25 years but the same has been challenged in the court and hence anyone above the age of 25 years can apply for the registration provided they will be provisionally admitted.</p>
    <br/>
    <br/>

    <h2 className="text-3xl font-bold text-indigo-600 mb-6">Fees for NEET Exams</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">NEET exams have designated fees that are taken from the students willing to appear for the exams. The fees go to the handling and the running of the examination and providing of the centers and all other management works. The fees are priced differently based on the categories. This is done to provide equal footing to the deprived people and tribes. Here’s the breakdown of the fees for the NEET exam:

General and OBC Category: 1400 INR
SC/ST/PWD/Transgender: 750 INR
The fees need to be paid during the time of registration and can be done by any electronic means or online payment options. Also, additional GST will be levied on top of the exam fees mentioned above.</p>

      

    </div>
  );


  const QualifyingCodesSection3 = () => (
    <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700 mb-10">
      <h2 className="text-3xl font-bold text-indigo-600 mb-6">The registration process of NEET Exams</h2>
      <p className="text-lg text-gray-600 text-2xl text-justify max-w-8xl mx-auto mb-1">

      The registration process of NEET exams is mandatory for anyone who wants to appear for the exams. Only the students who have registered themselves will be eligible to appear in the entrance paper and further their career ahead. We will here discuss the registration process down below. Check it out:
     <br/><br/>
<span className="font-bold">Step 1:</span> First visit the website of the NEET. The entire registration process has become online so you can only register for the same via the website.<br/>
<span className="font-bold">Step 2:</span> Click on the register as a new user option and then fill in the required details. This will allow you to create a registration on the website.<br/>
<span className="font-bold">Step 3:</span> Now use the registration and the password to login into the NEET website.<br/>
<span className="font-bold">Step 4:</span> Then click on the fill the form option and enter your details like personal and academic details. Also, select the desired test center as per your suitability.<br/>
<span className="font-bold">Step 5:</span>Once you’ve filled the form, take a look at it and overview it. Then attach the necessary documents like academic certificate, passport size photo, signature, and other details.<br/>
<span className="font-bold">Step 6:</span> Then click on submit the documents and continue to the payment option. There pay the desired fees for the NEET exam via any online payment mode and submit the final form.<br/>
<span className="font-bold">Step 7:</span>Your form will be submitted and you will be registered for the NEET exams.<br/>
<br/>
Once your registration is complete you can then wait for the admit card to be released from NTA. Once admit card is released you will have to download it and then take it along with you to the center. Here’s how to download the admit card:
<br/><br/>
<span className="font-bold">Step 1:</span> Open the NEET website on your browser.<br/>
<span className="font-bold">Step 2:</span>There click on the Download Admit Card option.<br/>
<span className="font-bold">Step 3:</span> Now enter the application number that you were provided when you filled the form and submitted it during registration. Also, enter your date of birth and the pin.<br/>
<span className="font-bold">Step 4:</span>Then the admit card will appear in the next tab. Click on the save this file and download the admit card.<br/>

      </p>
    <br/>
    <br/>

   

      

    </div>
  );


  return (
    <>
    <SEO
        title="NEET Coaching"
        description="Prepare for NEET with LETC Education’s expert faculty in Bangalore. Achieve your medical entrance goals with our comprehensive coaching."
        keywords="NEET coaching Bangalore, medical entrance prep, LETC Education NEET"
      />
    <div className="container mx-auto py-14 mt-20 md:mt-0 px-6">
      <div className="course-img mb-12">
        <img
          src={neet}
          loading="lazy"
          alt="Course"
          className="w-full h-[300px] rounded-3xl object-cover shadow-lg"
        />
      </div>
      <div className="about-us mb-16">
        <h1 className="font-bold text-indigo-700 text-4xl mb-8 tracking-wide text-center">
        What Is NEET Exam in India? Eligibility, Syllabus, Age Limit, Fees, Registration
        </h1>
        <p className="text-lg text-gray-600 text-center max-w-6xl mx-auto mb-12">
        The National Eligibility Entrance Test (NEET) is one of the major entrance tests conducted in India. This test is the basis for qualification in the MBBS and BDS fields across India. Any student who has completed their +2 needs to clear the NEET exams to pursue their career in the medical field. With a seat of over 90,000 and no. of registered students reaching up to 15.97 lakhs (As of 2020), the competition is quite tough making NEET one of the toughest entrance exams around.
        </p>
        <EligibilitySection
          
        />
        <QualifyingCodesSection />
        <QualifyingCodesSection1 />
        <QualifyingCodesSection2 />
        <QualifyingCodesSection3 />
        <div className="text-center mt-12">
          <a
            href="https://neet.nta.nic.in"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
          >
            Visit Official NEET Website
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default Neet;
