import React, { useState } from "react";
import { Link } from "react-router-dom";

const ExamHome = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="w-full mt- mb-14 px-4">
      <div className="exam-notifications bg-white shadow-lg rounded-lg p-6">
        <h3 className="font-extrabold text-slate-800 text-3xl mb-6 text-center tracking-wide">
          Latest Updates
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {[
            { title: "JEE MAIN EXAM-2025", link: "/jee" },
            { title: "NEET UG EXAM-2025", link: "/neet" },
            { title: "KEA Online Application-2025", link: "https://cetonline.karnataka.gov.in/UGONLINEAPPLICATION_2024/FORMS/Loginpage.aspx" },
            { title: "COMEDK UGET-2025", link: "https://www.comedk.org" },
            { title: "JEE ADVANCED-2025", link: "https://www.jeeadv.ac.in" },
            { title: "KCET COUNSELLING-2025", link: "https://cetonline.karnataka.gov.in/kea/" },
          ].map((exam, index) => (
            <div
              key={index}
              className={`exam-notifications p-5 bg-blue-50 rounded-lg transition-transform duration-300 ease-in-out transform ${
                activeIndex === index ? "scale-105" : "hover:bg-blue-100 hover:scale-105"
              }`}
              onClick={() => handleClick(index)}
            >
              <p className="font-bold text-lg text-[#2E3192] tracking-wide mb-3 break-words">
                {exam.title}
              </p>
              <Link
                to={exam.link}
                target="_blank"
                className="font-semibold blink text-sm text-red-600 tracking-wide hover:underline"
              >
                Click here
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamHome;
