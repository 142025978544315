import React, { useEffect } from "react";
import aboutTop from "../Assests/aboutbanner.jpg";
import SEO from './SEO';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <SEO
        title="About Us"
        description="Learn more about LETC Education, a leading coaching center in Bangalore for classes 8-12, IIT JEE, NEET, and Commerce."
        keywords="LETC Education, About LETC, Bangalore coaching center, IIT JEE coaching, NEET coaching, commerce classes"
      />
    <div className="container mx-auto py-14 mt-20 md:mt-0">
      {/* About Banner */}
      <div className="about-img mb-10">
        <img
          src={aboutTop}
          alt="About L.E.T.C"
          className="w-full h-[300px] rounded-3xl object-cover shadow-lg transition-transform duration-300 hover:scale-105"
        />
      </div>

      {/* About Us Section */}
      <div className="about-us mb-16">
        <h3 className="font-bold text-slate-800 text-4xl mb-5 text-center tracking-wider">
          About - L.E.T.C
        </h3>
        <p className="mb-5 text-xl text-gray-700 px-5 md:px-0 leading-relaxed text-justify">
          <b>L.E.T.C</b> was established with a great ambition of providing
          quality services in the field of education in the academic year 2009.
          A team of experienced and dedicated faculties took the responsibility
          of training the aspiring students for national and state-level
          Engineering and Medical Entrance Examination with great commitment
          under the visionary leadership of the management of <b>L.E.T.C</b>.
        </p>
        <p className="mb-5 text-xl text-gray-700 px-5 md:px-0 leading-relaxed text-justify">
          It is very proud to mention here that today <b>L.E.T.C</b> has emerged
          as one of the most successful coaching centres in North Bangalore with
          a proven track record of results in the previous years.
        </p>
        <p className="mb-5 text-xl text-gray-700 px-5 md:px-0 leading-relaxed text-justify">
          <b>L.E.T.C</b> has become the most sought and successful coaching
          centre in North Bangalore. From the year 2010, we have been coaching
          the students for IX and X exams also. From this academic year, IX + IIT/NEET
          Foundation and X + IIT / NEET Foundation have been started with greater
          conviction. At present, hundreds of students are studying in our
          established three branches. Students of XI, XII, I PUC, II PUC, are
          being trained for various competitive exams like JEE (Main) / BITSAT /
          K—CET / COMEDK / NEET / AIIMS / JIPMER.
        </p>
        <p className="mb-5 text-xl text-gray-700 px-5 md:px-0 leading-relaxed text-justify">
          Apart from the science stream, one more popular stream among 10th
          passed out children is Commerce. The Commerce stream also gives a range
          of career options for their future. We at L.E.T.C therefore included
          the commerce stream among the already existing products.
        </p>
      </div>

      {/* Vision and Strength Section */}
      <div className="w-full md:flex justify-center md:space-x-5">
        <div className="vision md:w-1/2 text-center mb-10 md:mb-0 bg-blue-100 p-5 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h4 className="font-bold text-3xl text-[#2E3192] mb-3 tracking-wider">
            OUR VISION
          </h4>
          <p className="font-medium text-lg text-gray-600 leading-relaxed text-justify">
            Our aim is to assist students to develop a positive attitude,
            competitive mind, and special skills in clearing National/State level
            entrance exams of reputed Engineering and Medical colleges.
          </p>
        </div>
        <div className="vision md:w-1/2 text-center bg-blue-100 p-5 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h4 className="font-bold text-3xl text-[#2E3192] mb-3 tracking-wider">
            OUR STRENGTH
          </h4>
          <p className="font-medium text-lg text-gray-600 leading-relaxed text-justify">
            Our strength is our faculty and staff.
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default About;
