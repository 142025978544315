import React, { useEffect, useState } from "react";
import letcContext from "../Context/letcContext";
import './Gallery.css';
import Modal from "react-modal";
import SEO from './SEO';
import image001 from "../Assests/1.jpeg"
import image002 from "../Assests/2.jpeg"
import image003 from "../Assests/3.jpeg"

import image004 from "../Assests/4.jpeg"
import image005 from "../Assests/5.jpeg"
import image006 from "../Assests/6.jpeg"

import image007 from "../Assests/7.jpeg"
import image008 from "../Assests/8.jpeg"
import image009 from "../Assests/9.jpeg"

import image0010 from "../Assests/10.jpeg"
import image0012 from "../Assests/12.jpg"
import image0011 from "../Assests/11.jpeg"

const Gallery = () => {
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };
  
  const images1 = [image001, image002, image003];
  const images2 = [image004, image005, image006];
  const images3 = [image007, image008, image009];
  const images4 = [image0010, image0011, image0012];
  
  return (
    <>
    <SEO
        title="Gallery"
        description="Browse the LETC Education gallery to see our vibrant learning environment in Bangalore and student activities."
        keywords="LETC Education gallery, learning environment, Bangalore coaching center, student activities"
      />
    <div className="md:pl-8 py-14 mt-20 md:mt-0 bg-blue-100">
      <div className="about-us mb-16">
        <h3 style={{textAlign:'center',fontSize:'50px'}}className="font-bold text-slate-700 text-2xl mb-5 ml-5 md:ml-0 tracking-wider">
          Our Memories
        </h3>
      </div>
       <div className="g-all" >
       <span className="g-1" >
        {images1.map((image, index) => (
          <img
            key={index}
            src={image}
            
            loading="lazy"
            alt={`Image ${index + 1}`}
            onClick={() => openModal(image)}
          />
        ))}

      </span>

      <span className="g-1">
        {images2.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            onClick={() => openModal(image)}
          />
        ))}
        
      </span>

      <span className="g-1">
        {images3.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            onClick={() => openModal(image)}
          />
        ))}
        
      </span>

      <span className="g-1">
        {images4.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            onClick={() => openModal(image)}
          />
        ))}
        
      </span>

      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
      >
        <button  style={{width:'100%',fontSize:'40px'}} onClick={closeModal}>Close</button>
        {selectedImage && <img style={{display: 'block',  
         margin: 'auto',    
         
        background:'transparent'}} 
          
          src={selectedImage} alt="Zoomed Image" />}
      </Modal>

      
        
        
        
    </div>
    </div>
    </>
  );
};

export default Gallery;
